import api from './index';

export default {
  getArticles(params) {
    return api.get('/api/articles', {params});
  },
  getArticle(id) {
    return api.get(`/api/articles/${id}`);
  },
};
